.button {
	border-radius: 30px;
	font-size: 12px;
	cursor: pointer;
	font-weight: 400;
	padding: 0 15px;
	text-transform: uppercase;
	height: 46px;
	background: #686A82;
	line-height: 40px;
	display: inline-block;
	border:3px solid transparent;
	color: #ffffff;
	outline: none;
	box-sizing: border-box;
	transition: all 0.5s ease;
	&:hover {
		background: #494B61;
	}
	.fa {
		font-size: 22px;
		vertical-align: sub;
		margin-right: 5px;
	}
	&.mini {
		padding: 0 15px;
	}
	&.primary {
		background-color: color(primary, base);
		&:hover {
			border: 3px solid color(primary, base);
			background: #fff;
			color: color(primary, base);
		}
		.fa {
			color: color(secondary, base);
		}
	}
	&.fourth {
		background-color: color(fourth, base);
		&:hover {
			border: 3px solid color(fourth, base);
			background: #fff;
			color: color(fourth, base);
		}
	}
	&.ternary {
		background-color: color(ternary, base);
		&:hover {
			border: 3px solid color(ternary, base);
			background: #fff;
			color: color(ternary, base);
		}
	}
	&.secondary {
		background-color: color(secondary, base);
		&:hover {
			border: 3px solid color(secondary, base);
			background: #fff;
			color: color(secondary, base);
		}
	}
	&.cancel {
		background-color: #F13A0E;
		&:hover {
			border: 3px solid #F13A0E;
			background: #fff;
			color: #F13A0E;
		}
	}
	&.outline-dark {
		background-color: rgba(0,0,0, 0.5);
		border-color: color(secondary, base);
	}
	&.outline {
		background: transparent;
		border-color: color(secondary, base);
	}
}

.button-medium {
	font-size: 12px;
	height: 35px;
	line-height: 35px;
	padding: 0 20px;
	@extend .button;
}