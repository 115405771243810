.sidebar {
	background: #EEEEEE;
	width: 100%;
	height: 100%;
	left: 0;
	overflow: hidden;
	border-radius: 8px;
	top: 0;
	overflow-y: auto;
	@include breakpoint(desktop){
		margin-bottom: 40px;
	}
	.category-bar {
		color: color(primary, base);
		font-size: 1.6em;
		line-height: 1.4em;
		border-bottom: 4px solid #fff;
		padding: 25px;
		margin-bottom: 20px;
	}
	.nav-menu {
		width: 100%;
		.header-menu {
			font-size: 13px;
			display: block;
			color: #fff;
			padding: 15px 25px;
			text-transform: uppercase;
			border-bottom: 1px solid rgba(255,255,255,0.5);
			&:last-child {
				border: 0;
			}
		}
	}
	.acordion-menu {
		padding: 0px 25px;
		&:last-child {
			.header {
				border-bottom: 0;
				padding-bottom: 0;
			}
			padding-bottom: 25px;
		}
		.header {
			font-size: 14px;
			padding: 15px 0;
			display: flex;
			line-height: 20px;
			justify-content: space-between;
			cursor: pointer;
			border-bottom: 1px solid #C9C9C9;
			color: #123756;
			.fa {
				color: #123756;
				font-size: 20px;
				transition: all 0.3 ease;
			}
			&.enabled {
				.fa {
					transform: rotate(180deg);
				}
			}
			&.active {
				font-weight: bold;
			}
		}
		.list {
			display: none;
			padding-top: 15px;
			a {
				font-size: 13px;
				padding: 9px 0;
				display: block;
				color: #123756;
				transition: opacity 0.5s ease;
				&:hover {
					opacity: 0.8;
				}
				.ico {
					width: 14px;
					height: 14px;
					vertical-align: middle;
					margin-right: 5px;
				}
			}
		}
	}
}

.bt-menu.fa {
	width: 46px;
	height: 46px;
	border-radius: 5px;
	border: 2px solid #fff;
	color: white;
	font-size: 20px;
	flex-shrink: 0;
	background: transparent;
	transition: all 0.4s ease;
	display: none;
	@include breakpoint(desktop) {
		display: inline-block;
	}
	&:hover {
		background: #fff;
		color: color(primary,base);
	}
}


.header-page {
	font-size: 14px;
	align-items: center;
	margin-bottom: 50px;
	&.flex {
		display: flex;
		justify-content: space-between;
		@include breakpoint(mobile) {
			flex-direction: column;
		}
	}
	.description {
		font-size: 13px;
		line-height: 18px;
	}
	.breadcumb {
		font-size: 13px;
		color: #656565;
		line-height: 20px;
		@include breakpoint(desktop) {
			display: none;
		}
		&.underline {
			text-decoration: underline;
		}
		a {
			color: #656565;
			&:hover {
				color: color(primary, base);
			}
		}
	}
	.select-form {
		span {
			text-transform: uppercase;
			margin: 0 10px;
			font-size: 12px;
		}
		.select {
			height: 40px;
			border-radius: 30px;
			transition: all 0.5s ease;
			padding: 0 15px;
			padding-right: 40px;
			background: url(../images/arrow-select.png) no-repeat 95% center;
			&:focus {
				border-color: color(primary, base);
				color: color(primary, base);
			}
		}
	}
}

.title-area {
	font-size: 23px;
	line-height: 30px;
	margin-bottom: 5px;
	font-weight: bold;
	&.blue {
		color: color(primary, base);
	}
	@include breakpoint(laptop) {
		font-size: 18px;
		line-height: 22px;
	}
}

.filter-bar {
	margin-bottom: 40px;
	.row-grid {
		margin: 0 -15px;
		[class*="grid-"] {
			padding: 0 15px;
			@include breakpoint(desktop) {
				width: 100%;
			}
		}
	}
	label {
		display: block;
		font-size: 14px;
		display: block;
		margin-bottom: 10px;
		cursor: pointer;
	}
	.block-date {
		display: flex;
		align-items: center;
		&:before {
			order: 1;
			content: '-';
			font-size: 16px;
			padding: 0 10px;
		}
		.input-default {
			&:nth-child(2) {
				order: 2;
			}
		}
	}
}


.block-text {
	p {
		font-size: 13px;
		margin-bottom: 20px;
		line-height: 1.6em;
	}
	h2 {
		font-weight: bold;
		text-transform: uppercase;
		font-size: 1.5em;
		line-height: 1.4em;
		margin-bottom: 15px;
	}
}

.container-modal {
	width: 450px;
	text-align: center;
	padding: 35px !important;
	border-radius: 25px;
	.title-area {
		text-align: center;
	}
	.button {
		width: 100%;
		margin-top: 10px;
	}
}

@keyframes down {
	0% {
		transform: translateY(-60%);
	}
	100% {
		transform: translateY(-20%);
	}
}

.wrapper-featured {
	height: 620px;
	width: 100%;
	position: relative;
	display: flex;
	justify-content: center;
	flex-direction: column;
	background: linear-gradient(to right, color(primary, base), color(primary, dark));
	.wrapper-locations {
		padding: 30px 0 30px;
		background: rgba(0, 0, 0, 0.05);
		border-top: 1px solid rgba(255, 255, 255, 0.2);
		margin-top: 30px;
		flex-grow: 1;
		@media (max-width: 600px) {
			display: none;
		}
		.list {
			display: flex;
			justify-content: space-between;
			align-items: center;
			color: #fff;
		}
		li {
			display: flex;
			text-align: left;
			width: 30%;
			margin-right: 2%;
			&:nth-child(3n) {
				margin: 0;
			}
			.fa {
				margin-right: 10px;
				color: color(secondary, base);
			}
			.box {
				flex-grow: 1;
			}
			.title {
				font-size: 1.6em;
				text-transform: uppercase;
				margin-bottom: 5px;
				line-height: 1;
				color: color(secondary, base);
			}
			.tel {
				font-size: 14px;
				margin-bottom: 15px;

				a {
					color: #FFF;
					text-decoration: underline;
				}
			}
			small {
				font-size: 13px;
				line-height: 20px;
				opacity: 0.9;
			}
		}
	}
	&.medium {
		height: 450px;
		padding-bottom: 0;
		&.detail {
			height: 300px;
			.block-search {
				flex-grow: 1;
				@include breakpoint(mobile){
					flex-grow: 0;
				}
			}
			.center {
				width: 100%;
				@include breakpoint(laptop){
					padding: 20px;
					font-size: 8px;
				}
				.info {
					padding-left: 0;
					text-align: left;
					margin-top: 5%;
					min-width: 30%;
				}
			}
		}
		.center {
			display: flex;
			align-items: center;
			height: 100%;
			width: 100%;
			&.top {
				align-items: flex-start;
			}
			&.auto {
				height: 25%;
				.info {
					margin-bottom: -95px;
					@media (max-width: 600px) {
						margin-bottom: 0;
					}
				}
			}
			&.left {
				.info {
					text-align: left;
				}
			}
			.block-text {
				color: #fff;
				margin: 15px 0;
			}
			.info {
				padding: 0 3%;
				text-align: center;
				&.text-center {
					width: 100%;
					.title {
						justify-content: center;
					}
				}
			}
			.picture-bottom {
				transform: none;
			}
			@include breakpoint(desktop) {
				.picture-top {
					display: none;
				}
				.picture-bottom {
					width: auto;
				}
			}
			@include breakpoint(mobile) {
				flex-direction: column;
				align-items: center;
				justify-content: center;
				.picture-bottom {
					display: none;
				}
			}
		}
	}
	.picture-top {
		transform: translateY(-30%);
		animation: down 2s 0.5s forwards;
	}
	.picture-bottom {
		align-self: flex-end;
		transition: transform 0.4s 0.5s ease;
		transform: translateY(20%);
		display: block;
		margin: 0 auto;
		@include breakpoint(desktop) {
			width: 100%;
			margin: 30px 0;
		}
	}
	.info {
		.title {
			font-size: 3em;
			color: #fff;
			line-height: 1.2em;
			display: flex;
			margin-bottom: 15px;
    		text-align: left;
			small {
				color: color(secondary, base);
				font-size: 30px;
				margin-right: 5px;
			}
		}
		.description {
			font-size: 1.4em;
			line-height: 1.5em;
			color: #fff;
			margin: 30px 0;
			@include breakpoint(desktop) {
				margin: 10px 0;
			}
		}
	}
	.slide-home {
		height: 100%;
		.slide {
			float: left;
			position: relative;
			background-position: center 90%;
			background-repeat: no-repeat;
			background-color: #123993;
			.block-search {
				.input {
					background: rgba(0, 0, 0, 0.75);
				}
			}
			.center {
				display: flex;
				align-items: center;
				height: 620px;
				justify-content: space-between;
			}
			@include breakpoint(desktop) {
				flex-direction: column;
				padding: 0 50px;
				box-sizing: border-box;
				justify-content: center;
				align-items: center;
				font-size: 8px;
			}
			&.bg-mobile {
				background-image: none;
				background-position: 8% top;
				background-repeat: no-repeat;
				background-size: cover;
				@media (max-width: 600px) {
					background-image: url(../images/banner_site_eletrorio.jpg);
					.picture-full {
						display: none;
					}
				}
			}
			.picture-full {
				width: 100%;
				transform: translateX(-100%);
				opacity: 0;
				transition: all 0.4s 0.5s ease;
			}
			&.slick-active {
				.picture-bottom {
					transform: translateY(0%);
				}
				.picture-full {
					transform: translateX(0%);
					opacity: 1;
				}
				.info {
					opacity: 1;
					transform: translateY(0);
				}
			}
			.info {
				width: 35%;
				opacity: 0;
				transform: translateY(-100%);
				transition: all 0.3s 0.5s ease;
				@include breakpoint(desktop) {
					width: 100%;
				}
			}
		}
		.arrow {
			position: absolute;
			top: 40%;
			font-size: 25px;
			background: transparent;
			color: #fff;
			z-index: 1001;
			transition: all 0.3s ease;
			cursor: pointer;
			&.fa-arrow-right {
				right: 2%;
			}
			&.fa-arrow-left {
				left: 2%;
			}
			&:hover {
				transform: scale(1.2);
			}
			@include breakpoint(desktop) {
				transform: scale(0.8);
			}
		}
		.slick-dots {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 80px;
			text-align: center;
			display: flex;
			z-index: 1000;
			justify-content: center;
			li {
				margin: 0 5px;
				width: 10px;
				cursor: pointer;
				height: 10px;
				transition: all 0.3s ease;
				border-radius: 100%;
				background: rgba(255,255,255,0.5);
				&.slick-active {
					background: #fff;
				}
				button {
					opacity: 0;
				}
			}
		}
	}
}

.block-search {
	width: 100%;
	position: relative;
	.input {
		width: 100%;
		height: 45px;
		border: 2px solid color(secondary, base);
		border-radius: 30px;
		background: #096895;
		padding: 0 15px;
		color: #fff;
		box-sizing: border-box;
		transition: all 0.3s ease;
		&::placeholder {
			color: #fff;
		}
		@include breakpoint(desktop) {
			font-size: 11px;
		}
		&:focus {
			border-color: rgba(255,255,255,0.9);
		}
	}
	.fa-search {
		position: absolute;
		right: 15px;
		top: 12px;
		cursor: pointer;
		background: transparent;
		color: #fff;
		font-size: 15px;
		&:hover {
			opacity: 0.85;
		}
	}
	.button {
		padding: 0 25px;
		margin: 0 5px;
	}
}

.main-header {
	width: 100%;
	left: 0;
	top: 0;
	z-index: 1010;
	position: fixed;
	@include breakpoint(desktop) {
		padding: 15px;
		box-sizing: border-box;
	}
	.center {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	&.active {
		background: linear-gradient(to right, color(primary, dark) 50%, color(primary, trans));
		border-bottom: 2px solid rgba(0,0,0,0.05);
	}
	.nav-menu {
		height: 80px;
		display: flex;
		align-items: center;
		@include breakpoint(desktop) {
			position: fixed;
			flex-direction: column;
			width: 250px;
			height: 100%;
			left: 0;
			top: 0;
			align-items: flex-start;
			background: #0C3252;
			z-index: 11000;
			transition: all 0.4s ease;
			transform: translateX(-100%);
			&.menu-open {
				transform: translateX(0);
			}
		}
		a {
			color: #fff;
			font-size: 13.5px;
			margin-left: 20px;
			position: relative;
			transition: color 0.5s ease;
			@include breakpoint(desktop) {
				margin: 15px 15px;
			}
			&:hover,&.enabled {
				color: color(secondary, base);
				&:after {
					width: 100%;
				}
			}
			&:after {
				content: '';
				width: 0%;
				height: 2px;
				background: color(secondary, base);
				display: block;
				position: absolute;
				bottom: -15px;
				transition: all 0.3s ease;
				left: 0;
			}
			&.last-link {
				color: color(secondary, base);
				border-left: 1px solid color(primary, light);
				margin-left: 15px;
				text-transform: uppercase;
				@include breakpoint(desktop) {
					border: 0;
					border-top: 1px solid color(primary, light);
					padding-top: 15px;
					width: 100%;
					box-sizing: border-box;
					margin: 10px 0;
				}
				&:after {
					display: none;
				}
			}
			.fa {
				margin-right: 5px;
				margin-left: 15px;
				font-size: 22px;
				color: #fff;
				position: relative;
				vertical-align: text-bottom;
				.count {
					font-family: $font;
					font-size: 11px;
					width: 17px;
					height: 17px;
					border-radius: 100%;
					right: -10px;
					text-align: center;
					line-height: 17px;
					background: #E39A10;
					position: absolute;
					top: -10px;
				}
			}
		}
	}
}


.wrapper-categories {
	margin-top: -50px;
	display: flex;
	position: relative;
	z-index: 1000;
	align-items: center;
	background: #EEEEEE;
	padding: 25px;
	border-radius: 8px;
	margin-bottom: 50px;
	@include breakpoint(desktop) {
		flex-wrap: wrap;
		padding: 15px;
	}
	.ico {
		width: 40px;
		height: 40px;
		display: block;
		margin: 0 auto 15px;
	}
	a {
		text-align: center;
		text-transform: uppercase;
		color: #0C3252;
		font-size: 13px;
		padding: 0 25px;
		width: 18%;
		box-sizing: border-box;
		line-height: 18px;
		border-right: 1px solid rgba(0,0,0,0.2);
		display: inline-block;
		@include breakpoint(desktop) {
			width: 50%;
			border: 0;
			padding: 10px;
		}
		&:hover {
			opacity: 0.8;
		}
		&.view-all {
			flex-grow: 1;
			border: 0;
			display: flex;
			align-items: center;
			.fa {
				background: #0C3252;
				color: white;
				width: 15px;
				height: 15px;
				flex-shrink: 0;
				line-height: 15px;
				margin-right: 0px;
				border-radius: 100%;
			}
		}
	}
}

.box-products {
	padding: 40px 0;
	@include breakpoint(desktop) {
		padding: 30px;
	}
}

.wrapper-shops {
	padding: 80px 0;
	background: color(fourth, base);
	@include breakpoint(desktop) {
		padding: 30px;
	}
}

.main-footer {
	padding: 10px 0;
	@include breakpoint(desktop) {
		padding: 15px;
	}
	.center {
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: #3E3E3E;
	}
	span {
		font-size: 12px;
		line-height: 22px;
	}
}

.wrapper-general {
	padding: 60px 0;
	@include breakpoint(desktop){
		&.center {
			padding: 30px;
		}
		padding: 30px;
		.box-products {
			padding: 15px 0;
		}
	}
}

.pagination {
	clear: both;
	a {
		width:35px;
		background: #EEEEEE;
		border-radius: 5px;
		color: #0C3252;
		height: 35px;
		display: inline-block;
		text-align: center;
		line-height: 35px;
		margin-bottom: 10px;
		margin-right: 5px;
		font-size: 12px;
		&.active {
			background: #e39a10;
			color: #fff;
		}
	}
}

.form-wrapper {
	padding: 60px 0 0;
	.button {
		border-radius: 3px;
		text-transform: none;
		margin-top: 20px;
		padding: 0 40px;
	}
}

.container-card {
	position: relative;
	z-index: 5;
	margin-top: -120px;
	background: #fff;
	margin-bottom: 40px;
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.14);
	border-radius: 8px;
	&.medium {
		width: 80%;
		margin: -150px auto 70px;
	}
	@include breakpoint(desktop) {
		margin: -150px 30px 80px;
	}
	.pic-featured {
		width: 100%;
		border-radius: 5px;
	}
	.wrapper {
		padding: 60px;
		@include breakpoint(desktop) {
			padding: 25px;
		}
		.box {
			padding: 20px 0 0;
		}
	}
}


.gallery {
	position: relative;
	margin: 40px 0;
	.image {
		width: 25%;
		box-sizing: border-box;
		border: 10px solid white;
		@include breakpoint(laptop) {
			width: 100% !important;
		}
		img {
			width: calc(100% - 30px);
			display: block;
			border-radius: 10px;
			
			box-sizing: border-box;
			width: 100%;
			height: 200px;
			object-fit: cover;
			@include breakpoint(laptop) {
				width: 100%;
				margin: 5px 0;
			}
		}
	}
	.slick-slide {
		float: left;
		outline: none;
	}
	.fa {
		position: absolute;
		top: 50%;
		background: transparent;
		cursor: pointer;
		font-size: 40px;
		&.fa-angle-left {
			left: -30px;
		}
		&.fa-angle-right {
			right: -40px;
		}
	}
	.slick-dots {
		display: flex;
		margin-left: 30px;
		padding-top: 30px;
		li {
			width: 12px;
			height: 12px;
			border-radius: 100%;
			background: rgba(0,0,0,0.2);
			margin: 0 5px;
			&.slick-active {
				background: rgba(0,0,0,0.5);
			}
			button {
				opacity: 0;
				cursor: pointer;
			}
		}
	}
}

.list-jobs {
	display: flex;
	flex-wrap: wrap;
	li {
		background: #F1F1F1;
		border-radius: 5px;
		padding: 20px;
		display: flex;
		box-sizing: border-box;
		align-items: center;
		width: 32%;
		margin-right: 2%;
		margin-bottom: 20px;
		justify-content: space-between;
		.ico {
			flex-shrink: 0;
			margin-right: 15px;
		}
		&:nth-child(3n) {
			margin-right: 0;
		}
		.info {
			flex-grow: 1;
			width: 60%;
			word-break: break-word;
		}
		.name {
			font-size: 1.6em;
			line-height: 1.4;
			color: #0C3252;
		}
		small {
			display: block;
			margin: 7px 0;
			font-size: 13px;
			opacity: 0.8;
		}
		p {
			font-size: 14px;
			font-weight: 500;
			color: #818A91;
			line-height: 22px;
		}
		@media (max-width: 600px) {
			width: 100%;
			margin-right: 0;
		}
	}
}

.overlaping-box.center {
	margin-top: -150px;
	position: relative;
	z-index: 2;
}

.list-dicas {
	margin-bottom: 45px;
	@media (max-width: 600px) {
		padding: 0 20px;
	}
	li {
		border-radius: 10px;
		padding: 50px;
		background: #ffffff;
		box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.07);
		max-width: 700px;
		margin: 0 auto 25px;
		img, iframe {
			width: 100%;
		}
		@media (max-width: 600px) {
			padding: 25px;
		}
		.title {
			font-size: 1.9em;
			font-weight: bold;
			text-transform: uppercase;
			line-height: 1.4em;
		}
		.date {
			font-size: 1.3em;
			line-height: 1.4em;
			margin: 5px 0 15px;
			color: #58646E;
		}
		.picture {
			width: 100%;
			margin: 20px 0;
		}
	}
}

.text-status {
	font-size: 16px;
	text-align: center;
}

.text-cart {
	padding: 15px;
    text-align: center;
    background: #d9edf7;
    font-size: 16px;
    color: #57676f;
}

.wrapper-gray {
	background: #F2F2F2;
}

.header-area {
	.picture {
		border-radius: 100%;
		float: left;
		margin-right: 15px;
		margin-bottom: 15px;
		width: 150px;
		height: 150px;
		object-fit: cover;
	}
	.title {
		text-transform: uppercase;
		font-size: 2em;
		line-height: 1.4em;
	}
	.name {
		font-size: 1.4em;
		font-weight: bold;
		padding: 5px 0;
	}
	.block-text {
		margin-top: 30px;
		font-size: 14px;
		line-height: 22px;
	}
}

.nav-socials {
	width: 60px;
	padding: 15px;
	box-sizing: border-box;
	background: #0c3252;
	position: fixed;
	right: 0;
	top: 50%;
	z-index: 2000;
	a {
		color: #fff;
		display: block;
		font-size: 20px;
		text-align: center;
		margin: 15px 0;
		transition: all 0.3s ease;
		&:hover {
			opacity: 0.7;
		}
	}
}