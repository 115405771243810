@import url("https://fonts.googleapis.com/css?family=Poppins:300, 400,600,700");
html {
  margin: 0;
  padding: 0;
  border: 0;
  overflow-x: hidden;
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, code, del, dfn, em, img, q, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, dialog, figure, footer, header, hgroup, nav, section {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline; }

a[href^="tel"] {
  color: inherit;
  text-decoration: none; }

article, aside, dialog, figure, footer, header, hgroup, nav, section, div {
  display: block; }

button, input[type="submit"], input[type="text"], select, input[type="checkbox"], input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  -khtml-appearance: none;
  appearance: none;
  outline: none;
  font-family: "Poppins", sans-serif; }

input[type="submit"], button {
  cursor: pointer;
  border: 0;
  outline: none; }

li, a, input {
  outline: none; }

body {
  line-height: 1;
  font-size: 10px;
  font-family: "Poppins", sans-serif;
  color: #2A2D34; }

ol, ul, li {
  list-style: none; }

table, th, td {
  vertical-align: middle; }

blockquote before, blockquote after, q before, q after {
  content: ''; }

a img {
  border: none; }

a {
  text-decoration: none; }

.clearfix:after {
  clear: both;
  display: block;
  content: ''; }

.reset {
  clear: both;
  display: block; }

.error {
  color: #9a0000 !important; }

.date::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none; }

input[type="checkbox"]:not(.check) {
  width: 20px;
  border-radius: 100%;
  background: #D6D6D6;
  vertical-align: middle;
  transition: all 0.3s ease;
  height: 20px; }
  input[type="checkbox"]:not(.check):checked {
    background: url(../images/checked.png) no-repeat center center #D7DEE2; }

input[type="checkbox"].check {
  width: 18px;
  border-radius: 2px;
  height: 18px;
  background: #fff;
  border: 1px solid #707070;
  vertical-align: middle; }
  input[type="checkbox"].check:checked {
    background: url(../images/checked-orange.png) no-repeat center center #fff; }

.slick-list {
  overflow: hidden; }

.text-center {
  text-align: center !important; }

a {
  color: #0180AD; }

.button, .button-medium {
  border-radius: 30px;
  font-size: 12px;
  cursor: pointer;
  font-weight: 400;
  padding: 0 15px;
  text-transform: uppercase;
  height: 46px;
  background: #686A82;
  line-height: 40px;
  display: inline-block;
  border: 3px solid transparent;
  color: #ffffff;
  outline: none;
  box-sizing: border-box;
  transition: all 0.5s ease; }
  .button:hover, .button-medium:hover {
    background: #494B61; }
  .button .fa, .button-medium .fa {
    font-size: 22px;
    vertical-align: sub;
    margin-right: 5px; }
  .button.mini, .mini.button-medium {
    padding: 0 15px; }
  .button.primary, .primary.button-medium {
    background-color: #0180AD; }
    .button.primary:hover, .primary.button-medium:hover {
      border: 3px solid #0180AD;
      background: #fff;
      color: #0180AD; }
    .button.primary .fa, .primary.button-medium .fa {
      color: #F6C540; }
  .button.fourth, .fourth.button-medium {
    background-color: #D7DEE2; }
    .button.fourth:hover, .fourth.button-medium:hover {
      border: 3px solid #D7DEE2;
      background: #fff;
      color: #D7DEE2; }
  .button.ternary, .ternary.button-medium {
    background-color: #666666; }
    .button.ternary:hover, .ternary.button-medium:hover {
      border: 3px solid #666666;
      background: #fff;
      color: #666666; }
  .button.secondary, .secondary.button-medium {
    background-color: #F6C540; }
    .button.secondary:hover, .secondary.button-medium:hover {
      border: 3px solid #F6C540;
      background: #fff;
      color: #F6C540; }
  .button.cancel, .cancel.button-medium {
    background-color: #F13A0E; }
    .button.cancel:hover, .cancel.button-medium:hover {
      border: 3px solid #F13A0E;
      background: #fff;
      color: #F13A0E; }
  .button.outline-dark, .outline-dark.button-medium {
    background-color: rgba(0, 0, 0, 0.5);
    border-color: #F6C540; }
  .button.outline, .outline.button-medium {
    background: transparent;
    border-color: #F6C540; }

.button-medium {
  font-size: 12px;
  height: 35px;
  line-height: 35px;
  padding: 0 20px; }

.list-products {
  position: relative; }
  .list-products.intern {
    margin: 0 -25px; }
    .list-products.intern li {
      width: 33%;
      margin-bottom: 40px; }
      .list-products.intern li:nth-child(3n) {
        border: 0; }
      @media (max-width: 520px) {
        .list-products.intern li {
          width: 50%; } }
  .list-products.scroll li:nth-child(4n) {
    border-right: 0; }
  .list-products li {
    float: left;
    padding: 0 30px;
    box-sizing: border-box;
    border-right: 1px solid #D0D0D0; }
    @media (max-width: 940px) {
      .list-products li {
        border: 0;
        text-align: center; } }
  .list-products .arrow {
    position: absolute;
    top: calc(60% - 50px);
    width: 50px;
    height: 50px;
    background: #EEEEEE;
    border-radius: 5px;
    transition: all 0.3s ease;
    font-size: 23px; }
    .list-products .arrow.fa-angle-right {
      right: -10%; }
      @media (max-width: 940px) {
        .list-products .arrow.fa-angle-right {
          right: -20px; } }
    .list-products .arrow.fa-angle-left {
      left: -10%; }
      @media (max-width: 940px) {
        .list-products .arrow.fa-angle-left {
          left: -20px; } }
    .list-products .arrow:hover {
      background: #0180AD;
      color: #fff; }

.card, .information-product, .table-products {
  display: block;
  width: 100%; }
  .card > .img-card, .information-product > .img-card, .table-products > .img-card {
    margin-bottom: 10px;
    max-width: 100%;
    display: block;
    height: 90px;
    object-fit: cover; }
    @media (max-width: 940px) {
      .card > .img-card, .information-product > .img-card, .table-products > .img-card {
        margin: 0 auto 10px; } }
    @media (max-width: 520px) {
      .card > .img-card, .information-product > .img-card, .table-products > .img-card {
        width: 70%;
        object-fit: contain; } }
  .card .categories, .information-product .categories, .table-products .categories {
    display: inline-block;
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
    background: #666666;
    padding: 5px 10px;
    border-radius: 30px; }
  .card .title, .information-product .title, .table-products .title {
    color: #0180AD;
    font-size: 16px;
    line-height: 21px;
    margin: 15px 0 5px;
    overflow: hidden;
    max-width: 80%; }
  .card .brand-prod, .information-product .brand-prod, .table-products .brand-prod {
    color: #666666;
    padding: 0;
    font-size: 12px;
    font-weight: bold;
    border-radius: 30px;
    display: none; }
  .card .value, .information-product .value, .table-products .value {
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
    color: #4B4B4B; }

.card-shop {
  width: 100%;
  background: white;
  position: relative; }
  .card-shop img {
    width: 100%; }
  .card-shop .content {
    padding: 25px;
    color: #818A91;
    font-size: 14px;
    line-height: 18px;
    padding-left: 100px;
    background: url(../images/marker.png) no-repeat left top #f6f6f6;
    min-height: 110px; }
    @media (max-width: 940px) {
      .card-shop .content {
        padding: 30px;
        background: #fff; } }
    .card-shop .content h3 {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 8px; }
    .card-shop .content p {
      margin-bottom: 8px;
      font-size: 12px; }

@media (max-width: 780px) {
  .information-product, .table-products {
    text-align: center; } }

.information-product .categories, .table-products .categories {
  margin-bottom: 15px; }

.information-product p, .table-products p {
  font-size: 1.3em;
  line-height: 1.4; }

.information-product .prices-box, .table-products .prices-box {
  display: flex;
  margin: 30px 0;
  align-items: center; }
  @media (max-width: 780px) {
    .information-product .prices-box, .table-products .prices-box {
      flex-direction: column; }
      .information-product .prices-box .value, .table-products .prices-box .value {
        margin-bottom: 20px; } }
  .information-product .prices-box .value, .table-products .prices-box .value {
    font-size: 2.8em;
    color: #0180AD; }

@media (max-width: 780px) {
  .information-product .button, .table-products .button, .information-product .button-medium, .table-products .button-medium {
    width: 100%; } }

.information-product .quantity, .table-products .quantity {
  padding: 0;
  text-transform: uppercase;
  font-size: 14px; }
  .information-product .quantity .bt, .table-products .quantity .bt {
    background: transparent;
    width: 12px; }
    .information-product .quantity .bt:hover, .table-products .quantity .bt:hover {
      font-weight: bold; }
  .information-product .quantity input, .table-products .quantity input {
    border: 1px solid #95989A;
    padding: 5px;
    margin: 0 5px;
    box-sizing: border-box;
    width: 40px;
    text-align: center; }

@media (max-width: 940px) {
  .table-overflow {
    overflow-x: scroll; }
    .table-overflow .table-products {
      width: 940px; } }

.table-products {
  width: 100%;
  display: table;
  font-size: 11px;
  overflow: hidden;
  border-radius: 5px; }
  @media (max-width: 940px) {
    .table-products {
      font-size: 9px; } }
  .table-products .heading, .table-products .footing {
    background: #e9e9e9;
    font-size: 14px; }
    .table-products .heading td, .table-products .footing td {
      color: #123756;
      padding: 15px !important; }
  .table-products .footing td:last-child {
    text-align: right; }
    .table-products .footing td:last-child strong {
      margin-left: 15px;
      color: #0180AD;
      font-size: 20px;
      font-weight: 300; }
  .table-products .footing .button, .table-products .footing .button-medium {
    height: 32px;
    margin-right: 5px;
    line-height: 29px;
    font-size: 11px; }
    .table-products .footing .button.dark, .table-products .footing .dark.button-medium {
      background: #00226b; }
    @media (max-width: 940px) {
      .table-products .footing .button, .table-products .footing .button-medium {
        width: auto; } }
  .table-products tr:not(.heading):not(.footing) td {
    border-bottom: 1px solid #c7c7c7; }
  .table-products tr:nth-last-child(2) td {
    border: 0 !important; }
  .table-products td {
    padding: 15px;
    text-align: center;
    width: 20%; }
    .table-products td:first-child {
      display: flex;
      text-align: left;
      padding-left: 0;
      width: 100%; }
    .table-products td .image {
      border-radius: 5px;
      border: 1px solid #707070;
      margin-right: 20px;
      width: 90px;
      height: 90px; }
      @media (max-width: 940px) {
        .table-products td .image {
          width: 40px;
          height: 40px;
          margin-right: 8px; } }
    .table-products td .remove-item {
      background: transparent;
      text-transform: uppercase;
      cursor: pointer;
      margin-top: 5px;
      border-radius: 30px;
      transition: all 0.3s ease; }
      @media (max-width: 940px) {
        .table-products td .remove-item {
          padding: 0; } }
      .table-products td .remove-item:hover {
        background: #b42a2a;
        color: #fff; }
    .table-products td .title-medium {
      color: #0180AD;
      font-size: 1.5em;
      line-height: 1.4em; }

.img-box {
  width: 100%; }
  .img-box .image {
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
    border: 2px solid #95989A; }

.input-default, .upload-file {
  height: 50px;
  background: #fff;
  color: black;
  font-size: 13px;
  padding: 0 15px;
  background: #fff;
  border: 2px solid #D2D2D2;
  border-radius: 5px;
  outline: none;
  transition: all 0.3s ease;
  margin-bottom: 15px;
  width: 100%;
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
  resize: none;
  display: block; }
  .input-default:focus, .upload-file:focus {
    border-color: #0180AD; }
  .input-default.date, .date.upload-file {
    background: url(../images/calendar.png) no-repeat 97% center #fff;
    padding-right: 35px; }
  .input-default.good, .good.upload-file {
    border-color: #D7DEE2; }

.legend-file {
  font-size: 12px;
  display: block;
  padding: 5px 0; }

.upload-file {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 0; }
  .upload-file span {
    line-height: 50px;
    padding: 0 15px;
    color: #8a8a8a; }
  .upload-file input {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    cursor: pointer;
    opacity: 0;
    position: absolute; }
  .upload-file .bt {
    background: #0e4558;
    text-transform: uppercase;
    font-weight: bold;
    padding: 0 15px;
    color: #fff;
    border-radius: 5px; }

.legend-file {
  display: block;
  text-align: left;
  font-size: 12px;
  margin-bottom: 40px; }

select.input-default, select.upload-file {
  background: url(../images/arrow-select.png) no-repeat 97% center #fff; }

textarea.input-default, textarea.upload-file {
  height: 180px;
  padding: 15px; }

.forms-sections {
  margin-top: 30px; }
  .forms-sections .title-section {
    font-size: 1.9em;
    line-height: 1.2em;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #BEBEBE; }
  .forms-sections .row-grid {
    margin: 0 -10px; }
    .forms-sections .row-grid [class*="grid-"] {
      padding: 0 10px;
      margin-bottom: 35px; }

.form-container .button, .form-container .button-medium {
  margin-top: 20px; }

.block-checks {
  height: 46px;
  display: flex;
  align-items: center;
  font-size: 14px; }
  .block-checks label {
    cursor: pointer;
    padding: 0 5px; }

.input-block > label {
  display: block;
  font-size: 14px;
  margin-bottom: 10px;
  cursor: pointer; }

.cancel-box {
  margin: 20px 0; }
  .cancel-box .row-grid {
    display: flex;
    align-items: center;
    margin: 0 -10px; }
    .cancel-box .row-grid [class*="grid-"] {
      margin-bottom: 0;
      padding: 10px; }
    .cancel-box .row-grid .button, .cancel-box .row-grid .button-medium {
      margin-top: 25px; }
    .cancel-box .row-grid .button:not(.mini), .cancel-box .row-grid .button-medium:not(.mini) {
      width: 100%; }

.footer-button {
  padding-top: 20px;
  text-align: center; }
  .footer-button .button, .footer-button .button-medium {
    padding: 0 45px;
    border-radius: 5px;
    min-width: 180px; }

.form-grid {
  padding: 40px 5% 0; }
  .form-grid [class*="grid-"] {
    padding: 0 5px; }
  .form-grid .row-grid {
    margin: 0 -5px; }

.nav-actions {
  display: flex;
  justify-content: center;
  padding: 0px 0 40px; }
  .nav-actions a {
    display: inline-block;
    background: #FFFFFF;
    text-transform: uppercase;
    color: #0C3252;
    font-size: 13px;
    cursor: pointer;
    vertical-align: middle;
    height: 50px;
    line-height: 50px;
    padding: 0 15px;
    margin: 0 10px; }
    .nav-actions a.disabled {
      color: #96A0A8; }

.row-grid {
  margin: 0 -18px; }
  .row-grid:after {
    content: '';
    display: block;
    clear: both; }

[class*="grid-"] {
  float: left;
  padding: 0 18px;
  margin-bottom: 10px;
  box-sizing: border-box; }

.grid-1 {
  width: 8.33333%; }

.grid-2 {
  width: 16.66667%; }

.grid-3 {
  width: 25%; }

.grid-4 {
  width: 33.33333%; }

.grid-5 {
  width: 41.66667%; }

.grid-6 {
  width: 50%; }

.grid-7 {
  width: 58.33333%; }

.grid-8 {
  width: 66.66667%; }

.grid-9 {
  width: 75%; }

.grid-10 {
  width: 83.33333%; }

.grid-11 {
  width: 91.66667%; }

.grid-12 {
  width: 100%; }

@media (max-width: 940px) {
  .grid-6, .grid-2, .grid-4, .grid-3, .grid-9, .grid-8 {
    width: 100%; } }

.sidebar {
  background: #EEEEEE;
  width: 100%;
  height: 100%;
  left: 0;
  overflow: hidden;
  border-radius: 8px;
  top: 0;
  overflow-y: auto; }
  @media (max-width: 940px) {
    .sidebar {
      margin-bottom: 40px; } }
  .sidebar .category-bar {
    color: #0180AD;
    font-size: 1.6em;
    line-height: 1.4em;
    border-bottom: 4px solid #fff;
    padding: 25px;
    margin-bottom: 20px; }
  .sidebar .nav-menu {
    width: 100%; }
    .sidebar .nav-menu .header-menu {
      font-size: 13px;
      display: block;
      color: #fff;
      padding: 15px 25px;
      text-transform: uppercase;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5); }
      .sidebar .nav-menu .header-menu:last-child {
        border: 0; }
  .sidebar .acordion-menu {
    padding: 0px 25px; }
    .sidebar .acordion-menu:last-child {
      padding-bottom: 25px; }
      .sidebar .acordion-menu:last-child .header {
        border-bottom: 0;
        padding-bottom: 0; }
    .sidebar .acordion-menu .header {
      font-size: 14px;
      padding: 15px 0;
      display: flex;
      line-height: 20px;
      justify-content: space-between;
      cursor: pointer;
      border-bottom: 1px solid #C9C9C9;
      color: #123756; }
      .sidebar .acordion-menu .header .fa {
        color: #123756;
        font-size: 20px;
        transition: all 0.3 ease; }
      .sidebar .acordion-menu .header.enabled .fa {
        transform: rotate(180deg); }
      .sidebar .acordion-menu .header.active {
        font-weight: bold; }
    .sidebar .acordion-menu .list {
      display: none;
      padding-top: 15px; }
      .sidebar .acordion-menu .list a {
        font-size: 13px;
        padding: 9px 0;
        display: block;
        color: #123756;
        transition: opacity 0.5s ease; }
        .sidebar .acordion-menu .list a:hover {
          opacity: 0.8; }
        .sidebar .acordion-menu .list a .ico {
          width: 14px;
          height: 14px;
          vertical-align: middle;
          margin-right: 5px; }

.bt-menu.fa {
  width: 46px;
  height: 46px;
  border-radius: 5px;
  border: 2px solid #fff;
  color: white;
  font-size: 20px;
  flex-shrink: 0;
  background: transparent;
  transition: all 0.4s ease;
  display: none; }
  @media (max-width: 940px) {
    .bt-menu.fa {
      display: inline-block; } }
  .bt-menu.fa:hover {
    background: #fff;
    color: #0180AD; }

.header-page {
  font-size: 14px;
  align-items: center;
  margin-bottom: 50px; }
  .header-page.flex {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 520px) {
      .header-page.flex {
        flex-direction: column; } }
  .header-page .description {
    font-size: 13px;
    line-height: 18px; }
  .header-page .breadcumb {
    font-size: 13px;
    color: #656565;
    line-height: 20px; }
    @media (max-width: 940px) {
      .header-page .breadcumb {
        display: none; } }
    .header-page .breadcumb.underline {
      text-decoration: underline; }
    .header-page .breadcumb a {
      color: #656565; }
      .header-page .breadcumb a:hover {
        color: #0180AD; }
  .header-page .select-form span {
    text-transform: uppercase;
    margin: 0 10px;
    font-size: 12px; }
  .header-page .select-form .select {
    height: 40px;
    border-radius: 30px;
    transition: all 0.5s ease;
    padding: 0 15px;
    padding-right: 40px;
    background: url(../images/arrow-select.png) no-repeat 95% center; }
    .header-page .select-form .select:focus {
      border-color: #0180AD;
      color: #0180AD; }

.title-area {
  font-size: 23px;
  line-height: 30px;
  margin-bottom: 5px;
  font-weight: bold; }
  .title-area.blue {
    color: #0180AD; }
  @media (max-width: 780px) {
    .title-area {
      font-size: 18px;
      line-height: 22px; } }

.filter-bar {
  margin-bottom: 40px; }
  .filter-bar .row-grid {
    margin: 0 -15px; }
    .filter-bar .row-grid [class*="grid-"] {
      padding: 0 15px; }
      @media (max-width: 940px) {
        .filter-bar .row-grid [class*="grid-"] {
          width: 100%; } }
  .filter-bar label {
    display: block;
    font-size: 14px;
    display: block;
    margin-bottom: 10px;
    cursor: pointer; }
  .filter-bar .block-date {
    display: flex;
    align-items: center; }
    .filter-bar .block-date:before {
      order: 1;
      content: '-';
      font-size: 16px;
      padding: 0 10px; }
    .filter-bar .block-date .input-default:nth-child(2), .filter-bar .block-date .upload-file:nth-child(2) {
      order: 2; }

.block-text p {
  font-size: 13px;
  margin-bottom: 20px;
  line-height: 1.6em; }

.block-text h2 {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.5em;
  line-height: 1.4em;
  margin-bottom: 15px; }

.container-modal {
  width: 450px;
  text-align: center;
  padding: 35px !important;
  border-radius: 25px; }
  .container-modal .title-area {
    text-align: center; }
  .container-modal .button, .container-modal .button-medium {
    width: 100%;
    margin-top: 10px; }

@keyframes down {
  0% {
    transform: translateY(-60%); }
  100% {
    transform: translateY(-20%); } }

.wrapper-featured {
  height: 620px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(to right, #0180AD, #00549d); }
  .wrapper-featured .wrapper-locations {
    padding: 30px 0 30px;
    background: rgba(0, 0, 0, 0.05);
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    margin-top: 30px;
    flex-grow: 1; }
    @media (max-width: 600px) {
      .wrapper-featured .wrapper-locations {
        display: none; } }
    .wrapper-featured .wrapper-locations .list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff; }
    .wrapper-featured .wrapper-locations li {
      display: flex;
      text-align: left;
      width: 30%;
      margin-right: 2%; }
      .wrapper-featured .wrapper-locations li:nth-child(3n) {
        margin: 0; }
      .wrapper-featured .wrapper-locations li .fa {
        margin-right: 10px;
        color: #F6C540; }
      .wrapper-featured .wrapper-locations li .box {
        flex-grow: 1; }
      .wrapper-featured .wrapper-locations li .title {
        font-size: 1.6em;
        text-transform: uppercase;
        margin-bottom: 5px;
        line-height: 1;
        color: #F6C540; }
      .wrapper-featured .wrapper-locations li .tel {
        font-size: 14px;
        margin-bottom: 15px; }
        .wrapper-featured .wrapper-locations li .tel a {
          color: #FFF;
          text-decoration: underline; }
      .wrapper-featured .wrapper-locations li small {
        font-size: 13px;
        line-height: 20px;
        opacity: 0.9; }
  .wrapper-featured.medium {
    height: 450px;
    padding-bottom: 0; }
    .wrapper-featured.medium.detail {
      height: 300px; }
      .wrapper-featured.medium.detail .block-search {
        flex-grow: 1; }
        @media (max-width: 520px) {
          .wrapper-featured.medium.detail .block-search {
            flex-grow: 0; } }
      .wrapper-featured.medium.detail .center {
        width: 100%; }
        @media (max-width: 780px) {
          .wrapper-featured.medium.detail .center {
            padding: 20px;
            font-size: 8px; } }
        .wrapper-featured.medium.detail .center .info {
          padding-left: 0;
          text-align: left;
          margin-top: 5%;
          min-width: 30%; }
    .wrapper-featured.medium .center {
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%; }
      .wrapper-featured.medium .center.top {
        align-items: flex-start; }
      .wrapper-featured.medium .center.auto {
        height: 25%; }
        .wrapper-featured.medium .center.auto .info {
          margin-bottom: -95px; }
          @media (max-width: 600px) {
            .wrapper-featured.medium .center.auto .info {
              margin-bottom: 0; } }
      .wrapper-featured.medium .center.left .info {
        text-align: left; }
      .wrapper-featured.medium .center .block-text {
        color: #fff;
        margin: 15px 0; }
      .wrapper-featured.medium .center .info {
        padding: 0 3%;
        text-align: center; }
        .wrapper-featured.medium .center .info.text-center {
          width: 100%; }
          .wrapper-featured.medium .center .info.text-center .title {
            justify-content: center; }
      .wrapper-featured.medium .center .picture-bottom {
        transform: none; }
      @media (max-width: 940px) {
        .wrapper-featured.medium .center .picture-top {
          display: none; }
        .wrapper-featured.medium .center .picture-bottom {
          width: auto; } }
      @media (max-width: 520px) {
        .wrapper-featured.medium .center {
          flex-direction: column;
          align-items: center;
          justify-content: center; }
          .wrapper-featured.medium .center .picture-bottom {
            display: none; } }
  .wrapper-featured .picture-top {
    transform: translateY(-30%);
    animation: down 2s 0.5s forwards; }
  .wrapper-featured .picture-bottom {
    align-self: flex-end;
    transition: transform 0.4s 0.5s ease;
    transform: translateY(20%);
    display: block;
    margin: 0 auto; }
    @media (max-width: 940px) {
      .wrapper-featured .picture-bottom {
        width: 100%;
        margin: 30px 0; } }
  .wrapper-featured .info .title {
    font-size: 3em;
    color: #fff;
    line-height: 1.2em;
    display: flex;
    margin-bottom: 15px;
    text-align: left; }
    .wrapper-featured .info .title small {
      color: #F6C540;
      font-size: 30px;
      margin-right: 5px; }
  .wrapper-featured .info .description {
    font-size: 1.4em;
    line-height: 1.5em;
    color: #fff;
    margin: 30px 0; }
    @media (max-width: 940px) {
      .wrapper-featured .info .description {
        margin: 10px 0; } }
  .wrapper-featured .slide-home {
    height: 100%; }
    .wrapper-featured .slide-home .slide {
      float: left;
      position: relative;
      background-position: center 90%;
      background-repeat: no-repeat;
      background-color: #123993; }
      .wrapper-featured .slide-home .slide .block-search .input {
        background: rgba(0, 0, 0, 0.75); }
      .wrapper-featured .slide-home .slide .center {
        display: flex;
        align-items: center;
        height: 620px;
        justify-content: space-between; }
      @media (max-width: 940px) {
        .wrapper-featured .slide-home .slide {
          flex-direction: column;
          padding: 0 50px;
          box-sizing: border-box;
          justify-content: center;
          align-items: center;
          font-size: 8px; } }
      .wrapper-featured .slide-home .slide.bg-mobile {
        background-image: none;
        background-position: 8% top;
        background-repeat: no-repeat;
        background-size: cover; }
        @media (max-width: 600px) {
          .wrapper-featured .slide-home .slide.bg-mobile {
            background-image: url(../images/banner_site_eletrorio.jpg); }
            .wrapper-featured .slide-home .slide.bg-mobile .picture-full {
              display: none; } }
      .wrapper-featured .slide-home .slide .picture-full {
        width: 100%;
        transform: translateX(-100%);
        opacity: 0;
        transition: all 0.4s 0.5s ease; }
      .wrapper-featured .slide-home .slide.slick-active .picture-bottom {
        transform: translateY(0%); }
      .wrapper-featured .slide-home .slide.slick-active .picture-full {
        transform: translateX(0%);
        opacity: 1; }
      .wrapper-featured .slide-home .slide.slick-active .info {
        opacity: 1;
        transform: translateY(0); }
      .wrapper-featured .slide-home .slide .info {
        width: 35%;
        opacity: 0;
        transform: translateY(-100%);
        transition: all 0.3s 0.5s ease; }
        @media (max-width: 940px) {
          .wrapper-featured .slide-home .slide .info {
            width: 100%; } }
    .wrapper-featured .slide-home .arrow {
      position: absolute;
      top: 40%;
      font-size: 25px;
      background: transparent;
      color: #fff;
      z-index: 1001;
      transition: all 0.3s ease;
      cursor: pointer; }
      .wrapper-featured .slide-home .arrow.fa-arrow-right {
        right: 2%; }
      .wrapper-featured .slide-home .arrow.fa-arrow-left {
        left: 2%; }
      .wrapper-featured .slide-home .arrow:hover {
        transform: scale(1.2); }
      @media (max-width: 940px) {
        .wrapper-featured .slide-home .arrow {
          transform: scale(0.8); } }
    .wrapper-featured .slide-home .slick-dots {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 80px;
      text-align: center;
      display: flex;
      z-index: 1000;
      justify-content: center; }
      .wrapper-featured .slide-home .slick-dots li {
        margin: 0 5px;
        width: 10px;
        cursor: pointer;
        height: 10px;
        transition: all 0.3s ease;
        border-radius: 100%;
        background: rgba(255, 255, 255, 0.5); }
        .wrapper-featured .slide-home .slick-dots li.slick-active {
          background: #fff; }
        .wrapper-featured .slide-home .slick-dots li button {
          opacity: 0; }

.block-search {
  width: 100%;
  position: relative; }
  .block-search .input {
    width: 100%;
    height: 45px;
    border: 2px solid #F6C540;
    border-radius: 30px;
    background: #096895;
    padding: 0 15px;
    color: #fff;
    box-sizing: border-box;
    transition: all 0.3s ease; }
    .block-search .input::placeholder {
      color: #fff; }
    @media (max-width: 940px) {
      .block-search .input {
        font-size: 11px; } }
    .block-search .input:focus {
      border-color: rgba(255, 255, 255, 0.9); }
  .block-search .fa-search {
    position: absolute;
    right: 15px;
    top: 12px;
    cursor: pointer;
    background: transparent;
    color: #fff;
    font-size: 15px; }
    .block-search .fa-search:hover {
      opacity: 0.85; }
  .block-search .button, .block-search .button-medium {
    padding: 0 25px;
    margin: 0 5px; }

.main-header {
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1010;
  position: fixed; }
  @media (max-width: 940px) {
    .main-header {
      padding: 15px;
      box-sizing: border-box; } }
  .main-header .center {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .main-header.active {
    background: linear-gradient(to right, #00549d 50%, rgba(1, 128, 173, 0.9));
    border-bottom: 2px solid rgba(0, 0, 0, 0.05); }
  .main-header .nav-menu {
    height: 80px;
    display: flex;
    align-items: center; }
    @media (max-width: 940px) {
      .main-header .nav-menu {
        position: fixed;
        flex-direction: column;
        width: 250px;
        height: 100%;
        left: 0;
        top: 0;
        align-items: flex-start;
        background: #0C3252;
        z-index: 11000;
        transition: all 0.4s ease;
        transform: translateX(-100%); }
        .main-header .nav-menu.menu-open {
          transform: translateX(0); } }
    .main-header .nav-menu a {
      color: #fff;
      font-size: 13.5px;
      margin-left: 20px;
      position: relative;
      transition: color 0.5s ease; }
      @media (max-width: 940px) {
        .main-header .nav-menu a {
          margin: 15px 15px; } }
      .main-header .nav-menu a:hover, .main-header .nav-menu a.enabled {
        color: #F6C540; }
        .main-header .nav-menu a:hover:after, .main-header .nav-menu a.enabled:after {
          width: 100%; }
      .main-header .nav-menu a:after {
        content: '';
        width: 0%;
        height: 2px;
        background: #F6C540;
        display: block;
        position: absolute;
        bottom: -15px;
        transition: all 0.3s ease;
        left: 0; }
      .main-header .nav-menu a.last-link {
        color: #F6C540;
        border-left: 1px solid #30c8fe;
        margin-left: 15px;
        text-transform: uppercase; }
        @media (max-width: 940px) {
          .main-header .nav-menu a.last-link {
            border: 0;
            border-top: 1px solid #30c8fe;
            padding-top: 15px;
            width: 100%;
            box-sizing: border-box;
            margin: 10px 0; } }
        .main-header .nav-menu a.last-link:after {
          display: none; }
      .main-header .nav-menu a .fa {
        margin-right: 5px;
        margin-left: 15px;
        font-size: 22px;
        color: #fff;
        position: relative;
        vertical-align: text-bottom; }
        .main-header .nav-menu a .fa .count {
          font-family: "Poppins", sans-serif;
          font-size: 11px;
          width: 17px;
          height: 17px;
          border-radius: 100%;
          right: -10px;
          text-align: center;
          line-height: 17px;
          background: #E39A10;
          position: absolute;
          top: -10px; }

.wrapper-categories {
  margin-top: -50px;
  display: flex;
  position: relative;
  z-index: 1000;
  align-items: center;
  background: #EEEEEE;
  padding: 25px;
  border-radius: 8px;
  margin-bottom: 50px; }
  @media (max-width: 940px) {
    .wrapper-categories {
      flex-wrap: wrap;
      padding: 15px; } }
  .wrapper-categories .ico {
    width: 40px;
    height: 40px;
    display: block;
    margin: 0 auto 15px; }
  .wrapper-categories a {
    text-align: center;
    text-transform: uppercase;
    color: #0C3252;
    font-size: 13px;
    padding: 0 25px;
    width: 18%;
    box-sizing: border-box;
    line-height: 18px;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    display: inline-block; }
    @media (max-width: 940px) {
      .wrapper-categories a {
        width: 50%;
        border: 0;
        padding: 10px; } }
    .wrapper-categories a:hover {
      opacity: 0.8; }
    .wrapper-categories a.view-all {
      flex-grow: 1;
      border: 0;
      display: flex;
      align-items: center; }
      .wrapper-categories a.view-all .fa {
        background: #0C3252;
        color: white;
        width: 15px;
        height: 15px;
        flex-shrink: 0;
        line-height: 15px;
        margin-right: 0px;
        border-radius: 100%; }

.box-products {
  padding: 40px 0; }
  @media (max-width: 940px) {
    .box-products {
      padding: 30px; } }

.wrapper-shops {
  padding: 80px 0;
  background: #D7DEE2; }
  @media (max-width: 940px) {
    .wrapper-shops {
      padding: 30px; } }

.main-footer {
  padding: 10px 0; }
  @media (max-width: 940px) {
    .main-footer {
      padding: 15px; } }
  .main-footer .center {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #3E3E3E; }
  .main-footer span {
    font-size: 12px;
    line-height: 22px; }

.wrapper-general {
  padding: 60px 0; }
  @media (max-width: 940px) {
    .wrapper-general {
      padding: 30px; }
      .wrapper-general.center {
        padding: 30px; }
      .wrapper-general .box-products {
        padding: 15px 0; } }

.pagination {
  clear: both; }
  .pagination a {
    width: 35px;
    background: #EEEEEE;
    border-radius: 5px;
    color: #0C3252;
    height: 35px;
    display: inline-block;
    text-align: center;
    line-height: 35px;
    margin-bottom: 10px;
    margin-right: 5px;
    font-size: 12px; }
    .pagination a.active {
      background: #e39a10;
      color: #fff; }

.form-wrapper {
  padding: 60px 0 0; }
  .form-wrapper .button, .form-wrapper .button-medium {
    border-radius: 3px;
    text-transform: none;
    margin-top: 20px;
    padding: 0 40px; }

.container-card {
  position: relative;
  z-index: 5;
  margin-top: -120px;
  background: #fff;
  margin-bottom: 40px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.14);
  border-radius: 8px; }
  .container-card.medium {
    width: 80%;
    margin: -150px auto 70px; }
  @media (max-width: 940px) {
    .container-card {
      margin: -150px 30px 80px; } }
  .container-card .pic-featured {
    width: 100%;
    border-radius: 5px; }
  .container-card .wrapper {
    padding: 60px; }
    @media (max-width: 940px) {
      .container-card .wrapper {
        padding: 25px; } }
    .container-card .wrapper .box {
      padding: 20px 0 0; }

.gallery {
  position: relative;
  margin: 40px 0; }
  .gallery .image {
    width: 25%;
    box-sizing: border-box;
    border: 10px solid white; }
    @media (max-width: 780px) {
      .gallery .image {
        width: 100% !important; } }
    .gallery .image img {
      width: calc(100% - 30px);
      display: block;
      border-radius: 10px;
      box-sizing: border-box;
      width: 100%;
      height: 200px;
      object-fit: cover; }
      @media (max-width: 780px) {
        .gallery .image img {
          width: 100%;
          margin: 5px 0; } }
  .gallery .slick-slide {
    float: left;
    outline: none; }
  .gallery .fa {
    position: absolute;
    top: 50%;
    background: transparent;
    cursor: pointer;
    font-size: 40px; }
    .gallery .fa.fa-angle-left {
      left: -30px; }
    .gallery .fa.fa-angle-right {
      right: -40px; }
  .gallery .slick-dots {
    display: flex;
    margin-left: 30px;
    padding-top: 30px; }
    .gallery .slick-dots li {
      width: 12px;
      height: 12px;
      border-radius: 100%;
      background: rgba(0, 0, 0, 0.2);
      margin: 0 5px; }
      .gallery .slick-dots li.slick-active {
        background: rgba(0, 0, 0, 0.5); }
      .gallery .slick-dots li button {
        opacity: 0;
        cursor: pointer; }

.list-jobs {
  display: flex;
  flex-wrap: wrap; }
  .list-jobs li {
    background: #F1F1F1;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    width: 32%;
    margin-right: 2%;
    margin-bottom: 20px;
    justify-content: space-between; }
    .list-jobs li .ico {
      flex-shrink: 0;
      margin-right: 15px; }
    .list-jobs li:nth-child(3n) {
      margin-right: 0; }
    .list-jobs li .info {
      flex-grow: 1;
      width: 60%;
      word-break: break-word; }
    .list-jobs li .name {
      font-size: 1.6em;
      line-height: 1.4;
      color: #0C3252; }
    .list-jobs li small {
      display: block;
      margin: 7px 0;
      font-size: 13px;
      opacity: 0.8; }
    .list-jobs li p {
      font-size: 14px;
      font-weight: 500;
      color: #818A91;
      line-height: 22px; }
    @media (max-width: 600px) {
      .list-jobs li {
        width: 100%;
        margin-right: 0; } }

.overlaping-box.center {
  margin-top: -150px;
  position: relative;
  z-index: 2; }

.list-dicas {
  margin-bottom: 45px; }
  @media (max-width: 600px) {
    .list-dicas {
      padding: 0 20px; } }
  .list-dicas li {
    border-radius: 10px;
    padding: 50px;
    background: #ffffff;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.07);
    max-width: 700px;
    margin: 0 auto 25px; }
    .list-dicas li img, .list-dicas li iframe {
      width: 100%; }
    @media (max-width: 600px) {
      .list-dicas li {
        padding: 25px; } }
    .list-dicas li .title {
      font-size: 1.9em;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 1.4em; }
    .list-dicas li .date {
      font-size: 1.3em;
      line-height: 1.4em;
      margin: 5px 0 15px;
      color: #58646E; }
    .list-dicas li .picture {
      width: 100%;
      margin: 20px 0; }

.text-status {
  font-size: 16px;
  text-align: center; }

.text-cart {
  padding: 15px;
  text-align: center;
  background: #d9edf7;
  font-size: 16px;
  color: #57676f; }

.wrapper-gray {
  background: #F2F2F2; }

.header-area .picture {
  border-radius: 100%;
  float: left;
  margin-right: 15px;
  margin-bottom: 15px;
  width: 150px;
  height: 150px;
  object-fit: cover; }

.header-area .title {
  text-transform: uppercase;
  font-size: 2em;
  line-height: 1.4em; }

.header-area .name {
  font-size: 1.4em;
  font-weight: bold;
  padding: 5px 0; }

.header-area .block-text {
  margin-top: 30px;
  font-size: 14px;
  line-height: 22px; }

.nav-socials {
  width: 60px;
  padding: 15px;
  box-sizing: border-box;
  background: #0c3252;
  position: fixed;
  right: 0;
  top: 50%;
  z-index: 2000; }
  .nav-socials a {
    color: #fff;
    display: block;
    font-size: 20px;
    text-align: center;
    margin: 15px 0;
    transition: all 0.3s ease; }
    .nav-socials a:hover {
      opacity: 0.7; }

.center {
  max-width: 1100px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media (max-width: 940px) {
    .center {
      padding: 0;
      max-width: 100%; } }
