@import url('https://fonts.googleapis.com/css?family=Poppins:300, 400,600,700');


// Variaveis de reutilização
$total: 1100;
$font: 'Poppins', sans-serif;

$gray: #4B4B4B;

$colors:(
	primary: (
		base: #0180AD,
		light: lighten(#0180AD, 25%),
		dark: darken(#00549D, 0%),
		trans: transparentize(#0180AD, 0.1)
	),
	secondary: (
		base: #F6C540,
		light: lighten(#F6C540, 15%),
		dark: darken(#F6C540, 15%),
		trans: transparentize(#F6C540, 0.5)
	),
	ternary: (
		base: #666666,
		light: lighten(#666666, 15%),
		dark: darken(#666666, 15%),
		trans: transparentize(#666666, 0.5)
	),
	fourth: (
		base: #D7DEE2,
		light: lighten(#D7DEE2, 15%),
		dark: darken(#D7DEE2, 15%),
		trans: transparentize(#D7DEE2, 0.5)
	)
);

@import 'functions/_mixins';
@import 'util/_reset';
@import "components/_buttons.scss";
@import "components/_cards.scss";
@import "components/_form.scss";
@import "components/_grids.scss";
@import "components/_layout.scss";

.center {
	max-width: $total + px;
	margin: 0 auto;
	box-sizing: border-box;
	@include breakpoint(desktop){
	    padding: 0;
	    max-width: 100%;
  	}
}
