html {
  margin: 0;
  padding: 0;
  border: 0;
  overflow-x: hidden;
  font-family: sans-serif; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}
body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, code, del, dfn, em, img, q, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, dialog, figure, footer, header, hgroup, nav, section {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
}
a[href^="tel"]{
    color:inherit;
    text-decoration:none;
}
article, aside, dialog, figure, footer, header, hgroup, nav, section, div {
  display: block;
}
button, input[type="submit"], input[type="text"], select, input[type="checkbox"], input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  -khtml-appearance: none;
  appearance: none; 
  outline: none;
  font-family: $font;
}
input[type="submit"], button {
  cursor: pointer;
  border: 0;
  outline: none;
}
li, a, input {outline: none}
body {
  line-height: 1;
  font-size: 10px;
  font-family: $font;
  color: #2A2D34;
}
ol,ul, li {
  list-style: none;
}

table, th, td {
  vertical-align: middle;
}

blockquote before, blockquote after, q before, q after {
  content: '';
}

a img {
  border: none;
}
a {
  text-decoration: none;
}
.clearfix {
  &:after {
    clear: both;
    display: block;
    content: ''
  }
}
.reset {
  clear: both;
  display: block;
}

.error {
  color: #9a0000 !important;
}


.date::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
}

input[type="checkbox"]:not(.check) {
  width: 20px;
  border-radius: 100%;
  background: #D6D6D6;
  vertical-align: middle;
  transition: all 0.3s ease;
  height: 20px;
  &:checked {
    background: url(../images/checked.png) no-repeat center center color(fourth, base);
  }
}

input[type="checkbox"].check {
  width: 18px;
  border-radius: 2px;
  height: 18px;
  background: #fff;
  border: 1px solid #707070;
  vertical-align: middle;
  &:checked {
    background: url(../images/checked-orange.png) no-repeat center center #fff;
  }
}

.slick-list {
  overflow: hidden;
}

.text-center {
  text-align: center !important;
}

a {
  color: color(primary, base);
}