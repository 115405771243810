.input-default {
	height: 50px;
	background: #fff;
	color: black;
	font-size: 13px;
	padding: 0 15px;
	background: #fff;
	border: 2px solid #D2D2D2;
	border-radius: 5px;
	outline: none;
	transition: all 0.3s ease;
	margin-bottom: 15px;
	width: 100%;
	font-family: $font;
	box-sizing: border-box;
	resize: none;
	display: block;
	&:focus {
		border-color: color(primary, base);
	}
	&.date {
		background: url(../images/calendar.png) no-repeat 97% center #fff;
		padding-right: 35px;
	}
	&.good {
		border-color: color(fourth, base);
	}
}

.legend-file {
	font-size: 12px;
    display: block;
    padding: 5px 0;
}

.upload-file {
	@extend .input-default;
	position: relative;
	display: flex;
	justify-content: space-between;
	padding: 0;
	span {
		line-height: 50px;
		padding: 0 15px;
		color: #8a8a8a;
	}
	input {
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		cursor: pointer;
		opacity: 0;
		position: absolute;
	}
	.bt {
		background: #0e4558;
		text-transform: uppercase;
		font-weight: bold;
		padding: 0 15px;
		color: #fff;
		border-radius: 5px;
	}
}

.legend-file {
	display: block;
	text-align: left;
	font-size: 12px;
	margin-bottom: 40px;
}

select {
	&.input-default {
		background: url(../images/arrow-select.png) no-repeat 97% center #fff;
	}
}

textarea {
	&.input-default {
		height: 180px;
		padding: 15px;
	}
}

.forms-sections {
	margin-top: 30px;
	.title-section {
		font-size: 1.9em;
		line-height: 1.2em;
		padding-bottom: 20px;
		margin-bottom: 20px;
		border-bottom: 1px solid #BEBEBE;
	}
	.row-grid {
		margin: 0 -10px;
		[class*="grid-"] {
			padding: 0 10px;
			margin-bottom: 35px;
		}
	}
	
}

.form-container {
	.button {
		margin-top: 20px;
	}
}

.block-checks {
	height: 46px;
	display: flex;
	align-items: center;
	font-size: 14px;
	label {
		cursor: pointer;
		padding: 0 5px;
	}
}

.input-block {
	> label {
		display: block;
		font-size: 14px;
		margin-bottom: 10px;
		cursor: pointer;
	}
}

.cancel-box {
	margin: 20px 0;
	.row-grid {
		display: flex;
		align-items: center;
		margin: 0 -10px;
		[class*="grid-"] {
			margin-bottom: 0;
			padding: 10px;
		}
		.button {
			margin-top: 25px;
		}
		.button:not(.mini) {
			width: 100%;
		}
	}
}

.footer-button {
	padding-top: 20px;
	text-align: center;
	.button {
		padding: 0 45px;
		border-radius: 5px;
		min-width: 180px;
	}
}

.form-grid{
	padding: 40px 5% 0;
	[class*="grid-"] {
		padding: 0 5px;
	}
	.row-grid {
		margin: 0 -5px;
	}
}

.nav-actions {
	display: flex;
	justify-content: center;
	padding: 0px 0 40px;
	a {
		display: inline-block;
		background: #FFFFFF;
		text-transform: uppercase;
		color: #0C3252;
		font-size: 13px;
		cursor: pointer;
		vertical-align: middle;
		height: 50px;
		line-height: 50px;
		padding: 0 15px;
		margin: 0 10px;
		&.disabled {
			color: #96A0A8;
		}
	}
}