$grid-gutter: 18px; // espaçamento entre as colunas
$grid-columns: 12; // Quantidade de colunas
$grid-max: 940px; // Tamanho do container

.row-grid {
	margin: 0 (-$grid-gutter);
	&:after {
		content: '';
		display:  block;
		clear: both;
	}
}

[class*="grid-"] {
	float: left;
	padding: 0 $grid-gutter;
	margin-bottom: 10px;
	box-sizing: border-box;
}
  
@for $i from 1 through $grid-columns {
	.grid-#{$i} {
		width: 100% / $grid-columns * $i;
	}
}
  
    
.grid-6, .grid-2, .grid-4, .grid-3, .grid-9, .grid-8 {
	@include breakpoint(desktop) {
		width: 100%;
	}
}