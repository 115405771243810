// @mixin font-face($style-name, $file, $family) {
//   $filepath: "../fonts/" + $family + "/" + $file;
//   @font-face {
//     font-family: $style-name;
//       src: url('#{$filepath}.eot');
//       src: url('#{$filepath}.eot?#iefix') format('embedded-opentype'),
//            url('#{$filepath}.woff') format('woff'),
//            url('#{$filepath}.ttf') format('truetype'),
//            url('#{$filepath}.svg##{$style-name}') format('svg');
//   }
// }

@function color($color-name, $color-variant) {
  @return map-get(map-get($colors, $color-name), $color-variant)
}


@mixin breakpoint($point) {
  @if $point == desktop {
     @media (max-width: 940px) { @content ; }
  }
   @else if $point == laptop {
     @media (max-width: 780px) { @content ; }
  }
   @else if $point == tablet {
     @media (max-width: 640px)  { @content ; }
  }
  @else if $point == mobile {
     @media (max-width: 520px)  { @content ; }
  }
}

