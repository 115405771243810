.list-products {
	position: relative;
	&.intern {
		margin: 0 -25px;
		li {
			width: 33%;
			margin-bottom: 40px;
			&:nth-child(3n) {
				border: 0;
			}
			@include breakpoint(mobile) {
				width: 50%;
			}
		}
	}
	&.scroll {
		li {
			&:nth-child(4n) {
				border-right: 0;
			}
		}
	}
	li {
		float: left;
		padding: 0 30px;
		box-sizing: border-box;
		border-right: 1px solid #D0D0D0;
		
		@include breakpoint(desktop) {
			border: 0;
			text-align: center;
		}
	}
	.arrow {
		position: absolute;
		top: calc(60% - 50px);
		width: 50px;
		height: 50px;
		background: #EEEEEE;
		border-radius: 5px;
		transition: all 0.3s ease;
		font-size: 23px;
		&.fa-angle-right {
			right: -10%;
			@include breakpoint(desktop) {
				right: -20px;
			}
		}
		&.fa-angle-left {
			left: -10%;
			@include breakpoint(desktop) {
				left: -20px;
			}
		}
		&:hover {
			background: color(primary, base);
			color: #fff;
		}
	}
}

.card {
	display: block;
	width: 100%;
	> .img-card {
		margin-bottom: 10px;
		max-width: 100%;
		display: block;
		height: 90px;
		object-fit: cover;
		@include breakpoint(desktop) {
			margin: 0 auto 10px;
		}
		@include breakpoint(mobile) {
			width: 70%;
			object-fit: contain;
		}
	}
	.categories {
		display: inline-block;
		font-size: 14px;
		color: #fff;
		text-transform: uppercase;
		background: color(ternary, base);
		padding: 5px 10px;
		border-radius: 30px;
	}
	.title {
		color: color(primary, base);
		font-size: 16px;
		line-height: 21px;
		margin: 15px 0 5px;
		overflow: hidden;
		max-width: 80%;
	}
	.brand-prod {
		color: #666666;
		padding: 0;
		font-size: 12px;
		font-weight: bold;
		border-radius: 30px;
		display: none;
	}
	.value {
		font-size: 16px;
		font-weight: 300;
		line-height: 22px;
		color: $gray;
	}
}

.card-shop {
	width: 100%;
	background: white;
	position: relative;
	img {
		width: 100%;
	}
	.content {
		padding: 25px;
		color: #818A91;
		font-size: 14px;
		line-height: 18px;
		padding-left: 100px;
		background: url(../images/marker.png) no-repeat left top #f6f6f6;
		min-height: 110px;
		@include breakpoint(desktop) {
			padding: 30px;
			background: #fff;
		}
		h3 {
			font-size: 14px;
			line-height: 20px;
			margin-bottom: 8px;
		}
		p {
			margin-bottom: 8px;
			font-size: 12px;
		}
	}
}

.information-product {
	@extend .card;
	@include breakpoint(laptop) {
		text-align: center;
	}
	.categories {
		margin-bottom: 15px;
	}
	p {
		font-size: 1.3em;
		line-height: 1.4;
	}
	.prices-box {
		display: flex;
		margin: 30px 0;
		align-items: center;
		@include breakpoint(laptop) {
			flex-direction: column;
			.value {
				margin-bottom: 20px;
			}
		}
		.value {
			font-size: 2.8em;
			color: color(primary, base);
		}
	}
	.button {
		@include breakpoint(laptop) {
			width: 100%;
		}		
	} 
	.quantity {
		padding: 0;
		text-transform: uppercase;
		font-size: 14px;
		.bt {
			background: transparent;
			width: 12px;
			&:hover {
				font-weight: bold;
			}
		}
		input {
			border: 1px solid #95989A;
			padding: 5px;
			margin: 0 5px;
			box-sizing: border-box;
			width: 40px;
			text-align: center;
		}
	}
}

.table-overflow {
	@include breakpoint(desktop) {
		overflow-x: scroll;
		.table-products {
			width: 940px;
		}
	}	
}

.table-products  {
	@extend .information-product;
	width: 100%;
	display: table;
	font-size: 11px;
	overflow: hidden;
	border-radius: 5px;
	@include breakpoint(desktop) {
		font-size: 9px;
	}
	.heading, .footing {
		background: #e9e9e9;
		font-size: 14px;
		td {
			color: #123756;
			padding: 15px !important;
		}
	}
	.footing {
		td {
			&:last-child {
				text-align: right;
				strong {
					margin-left: 15px;
					color: color(primary, base);
					font-size: 20px;
					font-weight: 300;
				}
			}
		}
		.button {
			height: 32px;
			margin-right: 5px;
			line-height: 29px;
			font-size: 11px;
			&.dark {
				background: color(primary, dark) - 50%;
			}
			@include breakpoint(desktop) {
				width: auto;
			}
		}
	}
	tr:not(.heading):not(.footing) {
		td {
			border-bottom: 1px solid #c7c7c7;
		}

	}
	tr:nth-last-child(2) {
		td {
			border: 0 !important;
		}
	}
	td {
		padding: 15px;
		text-align: center;
		width: 20%;
		&:first-child {
			display: flex;
			text-align: left;
			padding-left: 0;
			width: 100%;
		}
		.image {
			border-radius: 5px;
			border: 1px solid #707070;
			margin-right: 20px;
			width: 90px;
			height: 90px;
			@include breakpoint(desktop) {
				width: 40px;
				height: 40px;
				margin-right: 8px;
			}
		}
		.remove-item {
			background: transparent;
			text-transform: uppercase;
			cursor: pointer;
			margin-top: 5px;
			border-radius: 30px;
			transition: all 0.3s ease; 
			@include breakpoint(desktop) {
				padding: 0;
			}
			&:hover {
				background: rgb(180, 42, 42);
				color: #fff;
			}
		}
		.title-medium {
			color: color(primary, base);
			font-size: 1.5em;
			line-height: 1.4em;
		}
	}
}

.img-box {
	width: 100%;
	.image {
		width: 100%;
		object-fit: cover;
		border-radius: 8px;
		border: 2px solid #95989A;
	}
}